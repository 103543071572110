@use './../../../sassStyles/mixins' as m;

.mfaContainer{
    height:100%;
    padding:1rem 0.5rem 0.5rem 0.5rem;
    width: 100%;

}

.mfaLabel{
    text-align:center;
    margin:0.5rem 1rem 2rem 1rem;
    font-size: 16px ;
    line-height: 24px ;    
    color: #000000;
}

.resend-link {
  text-align:center;
  margin: 0 0 1rem 0;
  font-size: 16px ;
  line-height: 24px ;    
  color: #000000;
}

.link {
  text-align:center;
  margin:0.5rem 1rem 1rem 1rem;
  font-size: 16px ;
  line-height: 24px ;    
  color: #000000;
}

.form-label {
    text-align:center;
    margin: 0 0 1rem 0;
    font-size: 16px ;
    line-height: 24px ;    
    color: #000000;
}

.pop-up-title {
  text-align:center;
    margin:0.5rem 1rem 2rem 1rem;
    line-height: 24px ;
}

.mfacode-input-box a {
    text-align:center;
    margin:0.5rem 1rem 2rem 1rem;
    font-size: 16px ;
    line-height: 24px ;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    font-family: 'Roboto';
    font-weight: 400;   
}

.continueButton{
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem;
    text-transform: none !important;
    height: 44px;
    border-radius: 9px !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

.blue-text {
    color: #0050c3;
}

.mfacode-input-box {
  @include m.pop-up;
  width: 450px;
}

.popup a {
  margin:0.5rem 1rem 0 1rem;
  color: #2196f3;
  font-size: 14px;
  line-height: 20px; 
  text-transform: none;
}

.mfa-layout {
  background-color: white;
  width: 100%;
  height: 100%;
  max-width: 29em;
  position: relative;
  border-radius: 0.5em;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  justify-content: space-around;
  padding: 20px;
  margin-top: 50px;
}

.mfa-code-input {
  @include m.pop-up-top;
  width: 450px;
  overflow: auto;
  max-height: 90%;
}

@media screen and (max-width: 450px) {
  .mfa-code-input {
    @include m.pop-up-top-xs;
    max-width: 350px;
  }
}
@media screen and (max-width: 350px) , 
       screen and (max-height: 550px) {
  .mfa-code-input {
    top: 50%;
  }  
}

@media only screen and (max-width: 350px) {
  .mfa-code-input {
    margin-top: 50px;
    max-width: 300px;
  }
}
.mfa-code-input a {
  text-align:center;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  margin:0.5rem 1rem 0 1rem;
  color: #2196f3;
  text-transform: none;
}


@media screen and (max-height: 550px) {
  .mfacode-input-box {
    top: 50%;
  }  
}