// Colors

$AquaBlueDark : #1976D2;
$AquaBlueLight: #42A5F5;
$Black: #000000;
$White: #ffffff;

// Others

$PopupRadious: 8px;
$PopupPadding: 20px;