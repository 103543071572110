@use './../../../sassStyles/mixins' as m;

.mfaContainer{
    height:100%;
    padding:1rem 0.5rem 0.5rem 0.5rem;
    width: 100%;

}

.mfaLabel{
    text-align:center;
    margin:0.5rem 1rem 2rem 1rem;
    font-size: 16px ;
    line-height: 24px ;    
    color: #000000;
}

.link-label {
    text-align:center;
    margin:0.5rem 1rem 2rem 1rem;
    font-size: 16px ;
    line-height: 24px ;    
    color: #000000;
}

.appqr-pop-up-title {
  text-align:center;
    margin:-1.8rem 1rem 0 1rem;
    line-height: 24px ;
}

.mfacode-input-box a, .mfacode-input-box-appqr a {
    text-align:center;
    margin:0.5rem 1rem 2rem 1rem;
    font-size: 16px ;
    line-height: 24px ;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    font-family: 'Roboto';
    font-weight: 400;   
}

  .popup a {    
    margin:0.5rem 1rem 0 1rem;
    color: #2196f3;
  }

  .popup a.appqr-link {    
    color: #2196f3;  
    margin: 0;
  }

.continueButton{
    margin-top: 1.5rem !important;
    text-transform: none !important;
    height: 44px;
    border-radius: 9px !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

.blue-text {
    color: #0050c3;
}

.mfacode-input-box {
  @include m.pop-up;
  width: 450px;
}

.mfacode-input-box-appqr {
  @include m.pop-up;
  width: 450px;
}
@media screen and (max-width: 450px) {
  .mfacode-input-box {
    @include m.pop-up-top-xs;
    max-width: 350px;
    overflow: auto;
    max-height: 90%;
  }

  .mfacode-input-box-appqr {
    top: 50%;
    max-width: 360px;
    overflow: auto;
    max-height: 90%;
  }
}

@media screen and (max-width: 350px) {
  .mfacode-input-box {
    @include m.pop-up-top-xs;
    top: 50%;
    max-width: 300px;
    overflow: auto;
    max-height: 90%;
  }
  .mfacode-input-box-appqr {
    top: 50%;
    max-width: 300px;
    overflow: auto;
    max-height: 90%;
  }
}

@media screen and (max-height: 550px) {
  .mfacode-input-box {
    top: 50%;
    overflow: auto;
    max-height: 90%;
  }
  .mfacode-input-box-appqr {
    top: 50%;
    overflow: auto;
    max-height: 90%;
  }
}