* {
  margin: 0;
  box-sizing: border-box;
}


.cover {
  background-color: white;
  width: 100%;
  height: 100%;
  max-width: 29em;
  position: relative;
  border-radius: 0.5em;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  justify-content: space-around;
  padding: 20px;
  margin-top: 50px;
}

.page{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.secondary-label {
  font-size: small;
  font-weight: light;
}

.auth-key {
  text-align: center;
  display: block;
  font-size: large;
  font-weight: 500;
}

@media screen and (max-width: 450px) {
  .auth-key {
    font-size: small;    
  }  
}

@media screen and (max-width: 350px) {
  .auth-key {
    font-size: small;    
  }  
}

.auth-key-label {
  text-align: center;
  display: block;
  font-size: large;
  
}

