* {
  margin: 0;
  box-sizing: border-box;
}

.form-container {
  height: 100%;
  padding: 1rem;
}
.cover {
  background-color: white;
  width: 100%;
  height: 100%;
  max-width: 29em;
  position: relative;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  justify-content: space-around;
  padding: 20px;
  margin-top: 35px;
}

.page{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-image {
  width: 88%;
  padding: 2rem 1rem 2rem 1rem;
}

.rpw-label {
  // font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  padding-bottom: 1.5rem;
  color: #344054;  
}
.textField {
  padding-bottom: 1.5rem;
  //margin-bottom: 1.5rem !important;
  border-radius: 8px !important;
  color: #005FCC;
}

.resend-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
}

.resend-text {
  // font-family: Roboto!important;
  // font-size: 14px!important;
  font-weight: 500!important;
  // line-height: 20px!important;
  text-align: center;
  margin: 0 0 14px 0 !important;
  text-transform: capitalize !important;
  color: #1565C0 !important;
}

.rpw-button-text{
  // font-family: Roboto !important;
  // font-size: 14px !important;
  font-weight: 500 !important;
  // line-height: 24px !important;
  text-transform: capitalize !important;
  border-radius: 8px !important;
  height: 44px !important;
  text-shadow: 0 0 black;
}
.colorLink {
  color: #0050C3;
  font-size: 14px;
}
.bgLink {
  background-color: #0050C3;
}

