.userProfileSettingContainer{
    height:100%;
    padding:1rem;
}

.profileSettingCover {
  width: 85%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 10px;

}

.profileSettingPaddingTop {
  margin-top: 124px;
}
.bgprofileSettingTopRight {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.bgprofileSettingTopRight2 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 60px;
}
.bgprofileSettingButton {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(229, 246, 255);
}
.profileSettingColorText {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  font-family: "Roboto";
  text-transform: capitalize;
  line-height: 24px;
  letter-spacing: 0.17px;
  font-style: normal ;
}

.profileSettingLbButtonColorText {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize ;
  color: #1976d2;
  line-height: 20px;
}

a:hover {
  text-decoration: none;
  color: #1565c0;
  font-weight: 500;
  font-size: 14;
}
a:active {
  text-decoration: none;
  color: #1565c0;
  font-weight: 500;
  font-size: 14;
}
a:link {
  text-decoration: none;
  color: #1565c0;
  font-weight: 500;
  font-size: 14;
}
.profileSettingLinkNomal {
  color: #1565c0;
  font-weight: 500;
  font-size: 14px;
  text-transform: none;
}
.dvprofileSettingContainerMain {
  width: 100%;
  height: 100%;
}
.profileSettingSpanHeader {
  color: rgba(0, 0, 0, 0.57);
  font-weight: 300;
  font-size: 16px;
  font-family: "Roboto";
  text-transform: none;
  padding: 8 0;
  line-height: 24px;
  letter-spacing: 0.17px;
}
.profileSettingSpanHeader2 {
  color: #000000;
  font-weight: 500;
  font-size: 30px;
  font-family: "Roboto";
  text-transform: capitalize;
  line-height: 24px;
  letter-spacing: 0.17px;
}
.profileSettingSpanHeader4 {
  color: #000000;
  font-weight: 300;
  font-size: 14px;
  font-family: "Roboto";
  text-transform: none;
  line-height: 24px;
  letter-spacing: 0.17px;
}
.profileSettingSpanHeader3 {
  color: #000000;
  font-weight: 500;
  font-size: 20px;
  font-family: "Roboto";
  text-transform: capitalize;
  line-height: 24px;
  letter-spacing: 0.17px;
}
.dvprofileSettingHeaderTop {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-left: 60px;
}
.dvprofileSettingHeader {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.dvprofileSettingHeader2 {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.profileSettingField {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 30px !important;
  background-color: #ffffff;
}
.profileSettingColorText2 {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  font-family: "Roboto";
  vertical-align: middle;
  line-height: 24px;
  text-transform: capitalize;
  letter-spacing: 0.17px;
}

.profileSettingToggleButton {
  border-width: 0px;
}
.profileSettingdislabledLable {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000000;
}
.profileSettingTextInput {
  max-width: 400px;
  background-color: #fff;
  border-radius: 8px;
}
.profileSettingTextInputSmall {
  max-width: 80px;
  min-width: 30px;
  background-color: #fff;
  border-radius: 8px;
}
.profileSettingReadOnlyTextInput {
  background-color: rgba(140, 210, 250, 0.3);
  border-radius: 8px;
}

.profileSettingTextInputLarge {
  background-color: #fff;
  border-radius: 8px;
}
.profileSettingTextPhoneNumberInput {
  background-color: #fff;
  border-radius: 8px;
}
.profileSettingButtonNormal {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-transform: none;
}
.profileSettingGridHeight {
  margin-bottom: 1rem;
}
.profileSettingElementPadding{
  margin-bottom: 50px;
}
.profileSettingGridHeightEmail {
  margin-bottom: 1.0rem;
}
.profileSettingStackMarginRight {
  margin-right: -10px;
}
.padingHorizontal {
  padding: 8px 0;
}
.profileSettingSpanNone {
  text-transform: none;
  letter-spacing: 0.4px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}
.bgAddUserTopRight {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.profileSettingdvMarginBottom{
  margin-bottom:14px;
}
.profileSettingCol1{
  max-width: 170px;
}
.profileSettingCol2{
  max-width: 800px;
}
.profileSetting-form {
  margin-left:'60px'
}

@media screen and (max-width: 500px) {
  .profileSetting-form {
    margin-left:'10px'
  }
  .profileSettingSpanHeader2 {
    font-weight: 500;
    font-size: 25px;    
    line-height: 24px;
  }

  .profileSettingCover {
    //width: 95%; 
    margin-top: 0px;  
  }

  .profileSettingPaddingTop {
    margin-top: 50px;
  }

  .dvprofileSettingHeaderTop {
    margin: 0 auto;
    padding-left: 20px;
  }
  .profileSettingColorText {
    font-weight: 500;
    font-size: 14px;    
    line-height: 24px;
    letter-spacing: 0.17px;
    margin-left: -10px;
  }
}

