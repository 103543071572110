.signInContainer{
  height:100%;
  padding:1rem 0.5rem 0.5rem 0.5rem;
  width:100%
}
.passwordField{
  margin-bottom:1rem !important;
  border-radius:30px !important;
}
.rememberMeContainer{
  text-align: center;
  .rememberMeLabel{
      vertical-align: middle;
      font-size: 14px;
      line-height: 20px;
      color:#344054;
  }
}
.signInButton{
  margin-top: 1.5rem !important;
  border-radius: 9px !important;
  height: 44px;
  text-transform: none !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
.forgotPasswordContainer{
  text-align: center;
  margin: 1rem 1rem 0.5rem 1rem;
  color: #1565C0; 
  font-size: 14px;
  line-height: 20px; 
  .forgotPasswordLabel{
    text-transform: none;
    color: #0050C3;
  }
}
.form-container {
  height: 100%;
  padding: 1rem;
}
