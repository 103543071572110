/*add this in style to clear default show eye in MS Edge for @Mui material-ui */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.mainContainer{
  margin:10px;

}

.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Roboto-font-500{
  font-family: 'Roboto';
  font-weight: 500;
}

.Roboto-font-400{
  font-family: 'Roboto';
  font-weight: 400;
}

.tooltip {
  padding-bottom: 1.5rem;
  color: red;
}
.bgMenulandingPageTopRight {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  padding-top:20px;
}
.topMenuHeader{
  height: 50px;
}
