@use './variables' as *;

@mixin pop-up {
  position: absolute;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%);
  background-color: $White;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12); 
  border-radius: $PopupRadious;
  padding: $PopupPadding;
}

@mixin pop-up-top {
  position: absolute;
  top: 30%;
  left: 50%; 
  transform: translate(-50%, -50%);
  background-color: $White;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12); 
  border-radius: $PopupRadious;
  padding: $PopupPadding;
}

@mixin pop-up-top-xs {
  position: absolute;
  top: 30%;
  left: 50%; 
  //transform: translate(-50%, -50%);
  background-color: $White;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12); 
  border-radius: $PopupRadious;
  padding: $PopupPadding;
}