.topNavParent{
    padding-top: 10px;
    padding-bottom:10px;
    .small-bordered-svg{
        overflow: visible;
        border: 1px solid rgba(140, 210, 250, 0.6);
        padding: 5px 4px 2px 4px;
        border-radius: 7.2px;
        margin-right: 2rem;
    }

    .imgStyleTop {
        width: 25px;
        height: 25px;
    }

    .avatar{
        font-size: 20px;
    }
    .avatar-button{
        color:#0050c3 !important;
        margin-left: 2rem;
    }
}
@media screen and (max-width: 500px) {
    .topNavParent{
        padding-top: 0px;
        padding-bottom: 0px;
    }    
}
