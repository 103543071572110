@use './variables' as *;
@import './mixins';

body {
    margin: 0;
    font-family: 'Roboto' , 'Open Sans', Helvetica, sans-serif;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #EAF4FF;
   
  }
  * {
    margin: 0;
    padding: 0;
  
  } 

.dialog-title {  
    color: $AquaBlueDark ;
}

.dialog-description {
    color: $Black;
}

.button-label {   
    text-transform: none ;
    color: $AquaBlueLight ;
}

.error-msg {
  color: red;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
}

button { 
  &.cross {    
    &:hover {
      background-color: transparent;
    }
}
}

.bullet-points {
  li {
    list-style: disc outside none;
    display: list-item;
    margin-left: 1.5em;
    }
}

.cancel-link {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
