.RemoveMFAMethodPopupContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  //left: 34.17%;
  //right: 34.17%;
  //top: 30.96%;
  //   bottom: 36.62%;
  width: 250px;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  //border: '2px solid #000';
  border: 1.67667px solid rgba(0, 80, 195, 0.12);
  //boxShadow: 24;
  //p: 4,
  //paddding:4px,
  //box-shadow: 0px 1.67667px 0.838333px -0.838333px rgba(0, 0, 0, 0.2), 0px 0.838333px 0.838333px rgba(0, 0, 0, 0.14), 0px 0.838333px 2.515px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  padding: 20px;
  //border-radius: 10px;
  border-radius: 8px;
}
@media screen and (max-width: 300px) {
    .RemoveMFAMethodPopupContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        //left: 34.17%;
        //right: 34.17%;
        //top: 30.96%;
        //   bottom: 36.62%;
        width: 250px;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        //border: '2px solid #000';
        border: 1.67667px solid rgba(0, 80, 195, 0.12);
        //boxShadow: 24;
        //p: 4,
        //paddding:4px,
        //box-shadow: 0px 1.67667px 0.838333px -0.838333px rgba(0, 0, 0, 0.2), 0px 0.838333px 0.838333px rgba(0, 0, 0, 0.14), 0px 0.838333px 2.515px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
        padding: 20px;
        //border-radius: 10px;
        border-radius: 8px;
        overflow: auto;
        max-height: 90%;
      }
  }

  @media screen and (max-width: 500px) {
    .RemoveMFAMethodPopupContainer {        
        width: 350px;
        overflow: auto;
        max-height: 90%;
      }
  }
@media screen and (min-width: 400px) {
    .RemoveMFAMethodPopupContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        //left: 34.17%;
        //right: 34.17%;
        //top: 30.96%;
        //   bottom: 36.62%;
        width: 350px;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        //border: '2px solid #000';
        border: 1.67667px solid rgba(0, 80, 195, 0.12);
        //boxShadow: 24;
        //p: 4,
        //paddding:4px,
        //box-shadow: 0px 1.67667px 0.838333px -0.838333px rgba(0, 0, 0, 0.2), 0px 0.838333px 0.838333px rgba(0, 0, 0, 0.14), 0px 0.838333px 2.515px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
        padding: 20px;
        //border-radius: 10px;
        border-radius: 8px;
      }
  }
  @media screen and (min-width: 500px) {
    .RemoveMFAMethodPopupContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        //left: 34.17%;
        //right: 34.17%;
        //top: 30.96%;
        //   bottom: 36.62%;
        width: 300px;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        //border: '2px solid #000';
        border: 1.67667px solid rgba(0, 80, 195, 0.12);
        //boxShadow: 24;
        //p: 4,
        //paddding:4px,
        //box-shadow: 0px 1.67667px 0.838333px -0.838333px rgba(0, 0, 0, 0.2), 0px 0.838333px 0.838333px rgba(0, 0, 0, 0.14), 0px 0.838333px 2.515px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
        padding: 20px;
        //border-radius: 10px;
        border-radius: 8px;
      }
  }
  @media screen and (min-width: 600px) {
    .RemoveMFAMethodPopupContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        //left: 34.17%;
        //right: 34.17%;
        //top: 30.96%;
        //   bottom: 36.62%;
        width: 300px;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        //border: '2px solid #000';
        border: 1.67667px solid rgba(0, 80, 195, 0.12);
        //boxShadow: 24;
        //p: 4,
        //paddding:4px,
        //box-shadow: 0px 1.67667px 0.838333px -0.838333px rgba(0, 0, 0, 0.2), 0px 0.838333px 0.838333px rgba(0, 0, 0, 0.14), 0px 0.838333px 2.515px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
        padding: 20px;
        //border-radius: 10px;
        border-radius: 8px;
      }
  }
  @media screen and (min-width: 700px) {
    .RemoveMFAMethodPopupContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        //left: 34.17%;
        //right: 34.17%;
        //top: 30.96%;
        //   bottom: 36.62%;
        width: 456px;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        //border: '2px solid #000';
        border: 1.67667px solid rgba(0, 80, 195, 0.12);
        //boxShadow: 24;
        //p: 4,
        //paddding:4px,
        //box-shadow: 0px 1.67667px 0.838333px -0.838333px rgba(0, 0, 0, 0.2), 0px 0.838333px 0.838333px rgba(0, 0, 0, 0.14), 0px 0.838333px 2.515px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
        padding: 20px;
        //border-radius: 10px;
        border-radius: 8px;
      }
  }
  
  @media screen and (min-width: 800px) {
    .RemoveMFAMethodPopupContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        //left: 34.17%;
        //right: 34.17%;
        //top: 30.96%;
        //   bottom: 36.62%;
        width: 456px;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        //border: '2px solid #000';
        border: 1.67667px solid rgba(0, 80, 195, 0.12);
        //boxShadow: 24;
        //p: 4,
        //paddding:4px,
        //box-shadow: 0px 1.67667px 0.838333px -0.838333px rgba(0, 0, 0, 0.2), 0px 0.838333px 0.838333px rgba(0, 0, 0, 0.14), 0px 0.838333px 2.515px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
        padding: 20px;
        //border-radius: 10px;
        border-radius: 8px;
      }
  }

.RemoveMFAMethodPopupHeader {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  color: #000000;
}
.RemoveMFAMethodPopupButtonNormal {
  margin-top: 1rem;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  height: 36px;
  width: 71px;
}
.RemoveMFAMethodPopupButtonOK {
  margin-top: 1rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-transform: none;
  color: #1976d2;
}
.userGridHeightNormal {
  margin-bottom: 2.5rem;
}
.userTextInputLarge {
  //margin-top:1rem;
  background-color: #fff;
  border-radius: 8px;
}
.RemoveMFAMethodPopupBTcloseHeader {
  width: 12px;
  height: 12px;
  color: #000000;
}
.RemoveMFAMethodPopupImgIconClose {
  width: 12px;
  height: 12px;
  //padding-top: 30px;
  //margin-top: 20px;
  padding: 0rem 0rem 1rem 0rem;
  //text-align: center;
  //vertical-align: middle;
  align-content: center;
}
.RemoveMFAMethodPopupLable {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height, or 136% */
  display: flex;
  align-items: center;

  color: #000000;
}
.RemoveMFAMethodPopupLable2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height, or 136% */
  display: flex;
  align-items: center;

  color: #000000;
  text-transform: capitalize;
}
.RemoveMFAMethodPopupSpanBT {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  letter-spacing: 0.46px;
  text-transform: capitalize;

  /* Light/Primary/Main */

  color: #1976d2;
}
.RemoveMFAMethodPopupButtonNormal {
  margin-top: 1rem;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  height: 36px;
  width: 71px;
}
