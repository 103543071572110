.userProfileSettingContainer{
    height:100%;
    padding:1rem;
}

.resetPassCover {
  width: 85%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 10px;
}
.resetPassPaddingTop {
  margin-top: 124px;
}
.bgresetPassTopRight {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.bgresetPassTopRight2 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 60px;
}
.bgresetPassButton {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(229, 246, 255);
}
.resetPassColorText {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  font-family: "Roboto";
  text-transform: capitalize;
  line-height: 24px;
  letter-spacing: 0.17px;
  font-style: normal;
}

.resetPassLbButtonColorText {
  font-weight: 500 !important;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize !important;
  color: #1976d2 !important;
  line-height: 20px;
}

a:hover {
  text-decoration: none;
  color: #1565c0;
  font-weight: 500;
  font-size: 14;
}
a:active {
  text-decoration: none;
  color: #1565c0;
  font-weight: 500;
  font-size: 14;
}
a:link {
  text-decoration: none;
  color: #1565c0;
  font-weight: 500;
  font-size: 14;
}
.resetPassLinkNomal {
  color: #1565c0;
  font-weight: 500;
  font-size: 14px;
  text-transform: none;
}
.dvresetPassContainerMain {
  width: 100%;
  height: 100%;
  //min-width: 30em;
}
.resetPassSpanHeader {
  color: rgba(0, 0, 0, 0.57);
  font-weight: 300;
  font-size: 16px;
  font-family: "Roboto";
  text-transform: none;
  padding: 8 0;
  line-height: 24px;
  letter-spacing: 0.17px;
}
.resetPassSpanHeader2 {
  color: #000000;
  font-weight: 500;
  font-size: 30px;
  font-family: "Roboto";
  text-transform: capitalize;
  line-height: 24px;
  letter-spacing: 0.17px;
}
.resetPassSpanHeader4 {
  color: #000000;
  font-weight: 300;
  font-size: 14px;
  font-family: "Roboto";
  text-transform: none;
  line-height: 24px;
  letter-spacing: 0.17px;
}
.resetPassSpanHeader3 {
  color: #000000;
  font-weight: 500;
  font-size: 20px;
  font-family: "Roboto";
  text-transform: capitalize;
  line-height: 24px;
  letter-spacing: 0.17px;
}
.dvresetPassHeaderTop {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-left: 60px;
}
.dvresetPassHeader {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.dvresetPassHeader2 {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.resetPassField {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 30px !important;
  background-color: #ffffff;
}
.resetPassColorText2 {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  font-family: "Roboto";
  vertical-align: middle;
  line-height: 24px;
  text-transform: capitalize;
  letter-spacing: 0.17px;
}

.resetPassToggleButton {
  border-width: 0px;
}
.resetPassdislabledLable {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000000;
}
.resetPassTextInput {
  max-width: 164px;
  background-color: #fff;
  border-radius: 8px;
}
.resetPassTextInputSmall {
  max-width: 80px;
  min-width: 30px;
  background-color: #fff;
  border-radius: 8px;
}
.resetPassReadOnlyInput {
  //margin-top:1rem;
  //background-color: rgba(140, 210, 250, 0.3);
  background-color: #fff;
  border-radius: 8px;
}

.resetPassTextInputLarge {
  //margin-top:1rem;
  background-color: #fff;
  border-radius: 8px;
}
.resetPassTextPhoneNumberInput {
  //margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #fff;
  border-radius: 8px;
}
.resetPassButtonNormal {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-transform: none;
}
.resetPassGridHeight {
  margin-bottom: 2rem;
}
.padingHorizontal {
  padding: 8px 0;
}
.resetPassGridHeightNormal {
  margin-bottom: 2.5rem;
}
.resetPassGridHeightEdit {
  margin-bottom: 50px;
}
.resetPassStackMarginRight {
  margin-right: -10px;
}
.resetPassSpanNone {
  text-transform: none;
  letter-spacing: 0.4px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}
.bgAddUserTopRight {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.resetPassGridCol1{
  max-width:220px;
}
.resetPassword-form {
  margin-left:'60px'
}

@media screen and (max-width: 500px) {
  .resetPassword-form {
    margin-left:'0px'
  }
  .resetPassSpanHeader2 {
    font-weight: 500;
    font-size: 25px;
  }
  .resetPassColorText2 {
    font-size: 14px;
  }  
  .resetPassColorText {
    font-size: 14px;
  }
  .dvresetPassHeaderTop {
    padding-left: 20px;
  }
  .resetPassPaddingTop {
    margin-top: 50px;
  }
}